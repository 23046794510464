<template>
    <ag-grid-server-side
        id="userMgmt"
        :server-side-datasource="serverSideDatasource"
        :column-defs="columnDefs"
        :set-grid-api="setGridApi"
    >
    </ag-grid-server-side>
</template>

<script>
import AgGridServerSide from '@/components/table/AgGridServerSide'
import ColumnDef from '@/helpers/ag-grid/columnDef/staffUserManagement'
import DataSource from '@/helpers/ag-grid/datasource/graphql'
import { mapGetters } from 'vuex'

export default {
    components: {
        AgGridServerSide,
    },
    data: () => ({
        columnDefs: ColumnDef,
        gridApi: {},
    }),
    computed: {
        ...mapGetters({
            districtId: 'getDistrictId',
            lea: 'getDistrictLea',
        }),
    },
    methods: {
        setGridApi: function (gridApi) {
            this.gridApi = gridApi
        },
        serverSideDatasource: function () {
            const fetchRows = async (params) => {
                params.lea = this.lea
                const filters = this.gridApi.getFilterModel()
                const queryParams = Object.entries(filters).reduce(
                    (acc, [curr, { filter, type }]) => {
                        acc[curr] = filters[curr].filter
                        switch (type) {
                            case 'contains':
                                acc[curr] = `%${filter}%`
                                break
                            case 'startsWith':
                                acc[curr] = `${filter}%`
                                break
                            case 'endsWith':
                                acc[curr] = `%${filter}`
                                break
                            default:
                                acc[curr] = filter
                        }

                        return acc
                    },
                    { ...params }
                )

                await this.$store.dispatch('staff/get', queryParams)
            }

            return {
                getRows: DataSource.partialStore(fetchRows, 'staff').getRows,
            }
        },
    },
}
</script>
