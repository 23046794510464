export default [
    {
        headerName: 'Id',
        field: 'id',
        pinned: 'left',
    },
    {
        headerName: 'First Name',
        field: 'firstName',
        filterParams: {
            filterOptions: ['contains', 'equals'],
        },
    },
    {
        headerName: 'Last Name',
        field: 'lastName',
        filterParams: {
            filterOptions: ['contains', 'equals'],
        },
    },
    {
        headerName: 'Active',
        field: 'isActive',
        filterParams: {
            filterOptions: ['contains', 'equals'],
        },
    },
    {
        headerName: 'Staff Id',
        field: 'staffId',
        filterParams: {
            filterOptions: ['contains', 'equals'],
        },
    },
    {
        headerName: 'Email Address',
        field: 'emailAddress',
        filterParams: {
            filterOptions: ['contains', 'equals'],
        },
    },
    {
        headerName: 'City',
        field: 'city',
        filterParams: {
            filterOptions: ['contains', 'equals'],
        },
    },
    {
        headerName: 'State',
        field: 'state',
        filterParams: {
            filterOptions: ['contains', 'equals'],
        },
    },
    {
        headerName: 'Username',
        field: 'username',
        filterParams: {
            filterOptions: ['contains', 'equals'],
        },
    },
    {
        headerName: 'Illuminate Account ID',
        field: 'authXAccountId',
        filterParams: {
            filterOptions: ['contains', 'equals'],
        },
    },
    {
        headerName: 'Action',
        cellRendererFramework: 'RowActionButtons',
        cellRendererParams: {
            linkButtons: [
                {
                    routerLinkIds: ['id'],
                    routeName: 'staff-user-edit',
                    role: 'ROLE_USER_MANAGEMENT_EDIT',
                    btnText: 'Edit',
                    icon: 'fal fa-pencil',
                },
                {
                    routerLinkIds: ['authXAccountId'],
                    routeName: 'user-roles-edit',
                    role: 'ROLE_USER_MANAGEMENT_EDIT',
                    btnText: 'Edit Roles',
                    icon: 'fal fa-user-tag',
                },
            ],
        },
        width: 100,
        pinned: 'right',
        lockPinned: true,
        lockVisible: true,
        lockPosition: true,
        hide: false,
        suppressMenu: true,
        sortable: false,
        filter: false,
    },
]
